import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  public time: string;

  constructor() {
  }

  private moment() {
    const now = new Date();

    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const date = now.getDate();

    const day = now.getDay();
    const week = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六');

    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    this.time = `${year}-${this.addZero(month)}-${this.addZero(date)}&nbsp;&nbsp;${week[day]}&nbsp;&nbsp;${this.addZero(hours)}:${this.addZero(minutes)}:${this.addZero(seconds)}`;

    setTimeout(() => {
      this.moment();
    }, 1000);
  }

  private addZero(t) {
    return t < 10 ? '0' + t : t;
  }

  ngOnInit() {
    this.moment();
  }

}
