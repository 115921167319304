import {Component} from '@angular/core';
import { ActivatedRoute ,NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  public rooterChange: any;
  constructor(public router:ActivatedRoute) {
    // const state: RouterState = router.routerState;
    // router.events.subscribe(event:Event => {
    //   if(event instanceof NavigationStart) {
    //     console.log(74674)
    //     //
    //   } else if(event instanceof NavigationEnd) {
    //     //
    //   } else if(event instanceof NavigationCancel) {
    //     //
    //   } else if(event instanceof NavigationError) {
    //     //
    //   } else if(event instanceof RoutesRecognized) {
    //     //
    //   }
    // })
  
  }

  /**
 * 监听路由变化
 */
// listenRouterChange() {
  // this.rooterChange = this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationEnd) {
  //         //...
  //     }
  // });
  // this.router.NavigationStart(){}
  // console.log(this.router)
//   this.router.events.filter((event) => event instanceof NavigationStart)
//   .subscribe((event:NavigationStart) => {
//     //do something
//     console.log(this.router)
// });

  
// }

ngOnInit() {
  // this.listenRouterChange();
}

}
