import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
  {path: '', loadChildren: './home/home.module#HomeModule'},
  {path: 'detail', loadChildren: './detail/detail.module#DetailModule'},
  {path: 'monitor/:device_id', loadChildren: './monitor/monitor.module#MonitorModule'},
  {path: 'station/:device_id', loadChildren: './station/station.module#StationModule'},
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
